<template>
  <div class="withdraw">
    <!-- 导航栏 -->
    <van-nav-bar :title="$t('newAdd.Withdraw')">
      <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
      <!-- <i slot="left" class="iconfont icon-jiantou1" @click="$router.back()"></i> -->
      <template #right>
        <button @click="handleLinkPage('withdraw-list')" style="
            padding: calc(6rem / 16) calc(12rem / 16);
            background: #fff;
            color: #232f3e;
            border-radius: 4px;
            font-size: calc(14rem / 16);
          ">
          {{ $t('newAdd.Record') }}
        </button>
      </template>
    </van-nav-bar>

    <van-cell @click="handleLinkPage('add-bank-card')">
      <div slot="title" class="title">
        <span class="item-title">{{ $t('newAdd.BankCard') }}</span>
        <span class="item-right" v-if="bankInfo">
          <!-- <span class="item-value">{{ bankInfo.bank }}</span> -->
          <!-- language-ch 尾号 -->
          <span class="item-value-bank"><!-- tail number -->
            <div><span style="color: #666">{{ $t('newAdd.Name') }}:</span>{{ '\n' + bankInfo.name ? bankInfo.name : '' }}
            </div>
            <div><span style="color: #666">{{ $t('newAdd.BankName') }}:</span>{{ '\n' + bankInfo.bank ? bankInfo.bank : '' }}
            </div>
            <div><span style="color: #666">{{ $t('newAdd.CardNum') }}:</span>{{ '\n' + bankInfo.card ? bankInfo.card : '' }}
            </div>
          </span>
        </span>
        <span v-else>{{ $t('newAdd.Reading') }}</span>
      </div>
      <template #right-icon v-if="bankInfo.id">
        <van-icon name="arrow" slot="icon" />
      </template>
    </van-cell>

    <!-- language-ch 可提现余额 -->
    <van-cell>
      <div slot="title" class="balance">
        <span class="balance-title">{{ $t('newAdd.AllBalance') }} <!-- Withdrawable balance --></span>
        <span class="balance-right red">{{ $formatNumber(userInfo.money) }} <!-- {{ money_can }} --></span>
      </div>
      <!-- language-ch 总余额 -->
      <!-- <div slot="default" class="default">
        <span class="item-title">overall balance </span>
        <span class="item-value">{{
          userInfo.money ? userInfo.money : '{{verConfig.currency}} 0.00'
        }}</span>
      </div> -->
    </van-cell>
    <!-- language-ch 提现仍需消费：{{verConfig.currency}} -->
    <!-- <div class="item-need" v-if="userInfo.money_not > 0">
      Withdrawal will still be consumption:ZAR {{ userInfo.money_not }}
    </div> -->
    <!-- language-ch 是否可以提现 -->
    <!-- <van-cell>
      <div slot="title" class="balance">
        <span class="balance-title">Can I withdraw money</span>
        <span class="balance-right red"
          >{{ userInfo.money_not > 0 ? 'No' : 'Yes' }}
        </span>
      </div> -->
    <!-- 手续费 -->
    <!-- <div slot="default" class="default">
        <span class="item-title">procedure fee </span>
        <span class="item-value red">{{verConfig.currency}} 0.00</span>
      </div>
    </van-cell> -->
    <!-- language-ch 请输入提现金额 -->
    <van-field v-model="money" center v-if="country != 'tr'" clearable label-width="200" :label="verConfig.currency"
      type="number" :v-else="$t('newAdd.EnterWithdrawalAmount')">
      <!-- <template #button>
        <button @click="allHandler">{{$t('newAdd.AllOut')}}</button>
      </template> -->
    </van-field>

    <van-field v-model="money" center v-if="country == 'tr'" clearable label-width="200" label="TRY" type="number"
      :v-else="$t('newAdd.EnterWithdrawalAmount')">
      <!-- <template #button>
        <button @click="allHandler">{{$t('newAdd.AllOut')}}</button>
      </template> -->
    </van-field>

    <!-- language-ch 提 现 -->
    <button class="button" @click="Withdrawal">{{ $t('newAdd.AskNow') }}</button>

    <!-- 密码弹出层 -->
    <van-dialog v-model="isshowDialog" title="Password" show-cancel-button cancel-button-text="CANCEL"
      confirm-button-text="CONFIRM" confirmButtonColor="#00aa88" @confirm="onSubmit" @cancel="editValue = ''">
      <van-field v-model="editValue" autofocus="true" type="password" :placeholder="$t('newAdd.EnterPwdPlaceholder')" />
    </van-dialog>
    <!-- <Tabbar active="new-withdraw" /> -->
  </div>
</template>

<script>
import { GET_USER_BANK_INFO, WITHDRAW, getUserInfoNew } from '@/api'
import { isObject, count, getUserInfo, getToken } from '@/utils'
// import { userBank, extract } from '@/api/use.js'
import { NavBar, cell, icon, field, Dialog, Toast } from 'vant'
import Cookies from 'js-cookie'
import Tabbar from '@/components/Tabbar'
import { mapGetters } from 'vuex'
export default {
  name: '',
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    Tabbar,
  },
  data() {
    return {
      sms: '',
      country: Cookies.get('language'),
      money: 0,
      bankInfo: {},
      // userInfo: {},
      isshowDialog: false,
      editValue: '',
      money_not: 0,
      // isshowpassword: this.$route.query.isshowpassword
      //   ? this.$route.query.isshowpassword
      //   : false,
      paypassword: this.$route.query.paypassword
        ? this.$route.query.paypassword
        : true,
    }
  },
  created() {
    // getUserInfoNew()
    this.$store.dispatch('getUserInfo');
  },
  mounted() {
    this.getUserBankInfo()
    // this.userInfo = getUserInfo()
  },
  methods: {
    handleLinkPage(path) {
      if (getToken()) {
        this.$router.push(`/${path}`)
      } else {
        this.$toast($t('newAdd.NotLogin'))
        setTimeout(() => {
          this.$router.replace({ name: 'login' })
        }, 1000)
      }
    },
    getUserBankInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      })
      GET_USER_BANK_INFO()
        .then((r) => {
          Toast.clear()
          if (r.data.ret === 1) {
            Toast.clear()
            if (isObject(r.data.data) && count(r.data.data) > 0) {
              this.bank = r.data.data[0].bank
              this.bank_id = r.data.data[0].id
              this.bank_name = r.data.data[0].name
              this.bank_cardl4 = r.data.data[0].card_l4
              this.bankInfo = r.data.data[0]
              // this.checkUserInfo()
            } else {
              Toast.clear()
              // language-ch 您尚未设置银行卡，是否前往设置？
              Dialog.confirm({
                title: this.$t("newAdd.warmTitle"),
                confirmButtonText: this.$t("Button.confirm"),
                cancelButtonText: this.$t("Button.cancle"),
                message: this.$t('newAdd.NotHaveCard')
                // 'You have not set up your bank card, do you want to go to the setting?',
              })
                .then((res) => {
                  this.itemClick('BankCardAdd')
                })
                .catch((err) => {
                  this.$router.back()
                })
            }
          } else {
            Toast.clear()
            // Toast("拉取银行信息失败");
            Toast(this.$t('newAdd.GetBankInfoFail'))
            // this.$router.back()
          }
        })
        .catch((e) => {
          Toast.clear()
          console.error(e)
        })
    },
    itemClick(page) {
      this.$router.push({ name: page })
    },
    allHandler() {
      // this.money = this.money_can
      this.money = this.userInfo.money;
      // if (this.money_can !== 0) {
      //   this.money = this.money_can
      // }
    },

    Withdrawal() {
      // if (this.money_can === 0) {
      //   Toast('The withdrawal balance is zero')
      //   return
      // }
      // if (this.money === 0) {
      //   Toast('Please fill in the amount')
      //   return
      // }
      // this.isshowDialog = true
      this.confirm()
    },

    confirm() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      })
      WITHDRAW({
        money: this.money,
        // paypass: this.editValue,
        bankid: this.bank_id,
      })
        .then((r) => {
          Toast.clear()
          if (r.data.ret === 1) {
            Toast.clear()
            Toast(r.data.msg)
            getUserInfoNew()
            this.$router.back()
          } else {
            Toast.clear()
            Toast(r.data.msg)
            // setTimeout(() => {
            //   this.$router.replace({ name: 'login' })
            // }, 1000)
          }
        })
        .catch((e) => {
          Toast.clear()
          console.error(e)
        })
    },

    // 提交提现
    async onSubmit() {
      this.isshowDialog = false
      if (this.editValue.trim().length !== 4) {
        Toast(this.$t('newAdd.EnterValidPayPwd'))
      } else {
        const { data } = await extract({
          money: this.money,
          paypass: this.editValue,
          bankid: this.bankInfo.id,
        })
        Toast(data.msg)
        this.editValue = ''
        this.$router.push('/login')
      }
    },
  },
  computed: {
    money_can() {
      return this.userInfo.money_not > 0 ? 0 : this.userInfo.money
    },
    ...mapGetters(["userInfo"])
  },
}
</script>

<style lang="less" scoped>
@nav-bar-background-color: #ff0000;

.withdraw {
  width: 100%;
  height: 100vh;
  background-color: #f4f8f9;

  // font-family: 'PingFang-Regular';

  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      background-color: var(--navBg);
      height: calc(50rem / 16);
    }

    /deep/.van-nav-bar__title {
      color: #fff;
    }

    button {
      background-color: var(--navBg);
      border: none;
      color: #fff;
      font-size: calc(10rem / 16);
    }

    .iconfont {
      font-size: calc(22rem / 16);
      color: #fff;
    }
  }

  .van-cell {
    margin: calc(7.5rem / 16) 0;
    font-size: calc(12rem / 16);
    color: #000;
    display: flex;
    align-items: center;

    // i {
    //   // margin-top: calc(18rem / 16);
    // }
    .title {
      display: flex;
      align-items: center;

      .item-right {
        width: calc(250rem / 16);
        display: flex;
        flex-direction: row;
        margin-left: calc(15rem / 16);
      }
    }

    .balance {
      width: calc(185rem / 16);

      .balance-title {
        width: calc(200rem / 16);
      }

      .balance-right {
        margin-left: calc(10rem / 16);
      }
    }

    .default {
      color: #000;

      .item-value {
        margin-left: calc(10rem / 16);
      }
    }

    .red {
      color: #ff0000;
    }
  }

  .item-need {
    color: #dc1323;
    text-align: right;
    font-size: calc(12rem / 16);
    margin-top: calc(12rem / 16);
    margin-right: calc(12rem / 16);
    margin-bottom: calc(12rem / 16);
  }

  .van-field {
    font-size: calc(15rem / 16);

    /deep/.van-field__label {
      width: calc(15rem / 16);
    }

    button {
      border: none;
      color: #232f3e;
      background-color: #fff;
    }
  }

  .button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(340rem / 16);
    height: calc(43rem / 16);
    font-size: calc(15rem / 16);
    margin-top: calc(31rem / 16);
    background-color: var(--navBg);
    border-radius: calc(5rem / 16);
    border: none;
    color: #fff;
  }

  .van-field {
    /deep/.van-field__body {
      input {
        text-align: center;
      }
    }
  }
}
</style>
